:root {
  /* COLORS */
  /*
    Use this tool to generate rich color sets:
    https://ionicframework.com/docs/theming/color-generator
  */
  --color-primary: #46ACA6;
  --color-primary-rgb: 70, 172, 166;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-primary-shade: #3e9792;
  --color-primary-tint: #59b4af;
  --color-blue: #3087e8;
  --color-blue-rgb: 48,135,232;
  --color-blue-contrast: #ffffff;
  --color-blue-contrast-rgb: 255,255,255;
  --color-blue-shade: #2a77cc;
  --color-blue-tint: #4593ea;
  --color-orange: #fa853c;
  --color-orange-rgb: 250,133,60;
  --color-orange-contrast: #ffffff;
  --color-orange-contrast-rgb: 255,255,255;
  --color-orange-shade: #dc7535;
  --color-orange-tint: #fb9150;
  --color-facebook: #39599E;
  --color-facebook-rgb: 57, 89, 158;
  --color-facebook-contrast: #ffffff;
  --color-facebook-contrast-rgb: 255, 255, 255;
  --color-facebook-shade: #324e8b;
  --color-facebook-tint: #4d6aa8;
  --color-google: #E03945;
  --color-google-rgb: 224,57,69;
  --color-google-contrast: #ffffff;
  --color-google-contrast-rgb: 255,255,255;
  --color-google-shade: #c5323d;
  --color-google-tint: #e34d58;
  --color-gray-lighter: #eeeeee;
  --color-gray-lighter-rgb: 238,238,238;
  --color-gray-lighter-contrast: #000000;
  --color-gray-lighter-contrast-rgb: 0,0,0;
  --color-gray-lighter-shade: #d1d1d1;
  --color-gray-lighter-tint: #f0f0f0;
  --color-gray-light: #cccccc;
  --color-gray-light-rgb: 204,204,204;
  --color-gray-light-contrast: #000000;
  --color-gray-light-contrast-rgb: 0,0,0;
  --color-gray-light-shade: #b4b4b4;
  --color-gray-light-tint: #d1d1d1;
  --color-gray: #BFBFBF;
  --color-gray-rgb: 191,191,191;
  --color-gray-contrast: #000000;
  --color-gray-contrast-rgb: 0,0,0;
  --color-gray-shade: #a8a8a8;
  --color-gray-tint: #c5c5c5;
  --color-gray-dark: #949A9E;
  --color-gray-dark-rgb: 148,154,158;
  --color-gray-dark-contrast: #000000;
  --color-gray-dark-contrast-rgb: 0,0,0;
  --color-gray-dark-shade: #82888b;
  --color-gray-dark-tint: #9fa4a8;
  --color-gray-darker: #333333;
  --color-gray-darker-rgb: 51,51,51;
  --color-gray-darker-contrast: #ffffff;
  --color-gray-darker-contrast-rgb: 255,255,255;
  --color-gray-darker-shade: #2d2d2d;
  --color-gray-darker-tint: #474747;
  --color-white: #ffffff;
  --color-white-rgb: 255,255,255;
  --color-white-contrast: var(--color-gray-dark);
  --color-white-contrast-rgb: var(--color-gray-dark-rgb);
  --color-white-shade: #e0e0e0;
  --color-white-tint: #ffffff;
  --color-dark: #172328;
  --color-dark-rgb: 23,35,40;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255,255,255;
  --color-dark-shade: #141f23;
  --color-dark-tint: #2e393e;
  --color-disabled: #BFBFBF;
  --color-disabled-rgb: 191,191,191;
  --color-disabled-contrast: #ffffff;
  --color-disabled-contrast-rgb: 255,255,255;
  --color-disabled-shade: #a8a8a8;
  --color-disabled-tint: #c5c5c5;
  --color-error: #FF5757;
  --color-error-rgb: 255,87,87;
  --color-error-contrast: #ffffff;
  --color-error-contrast-rgb: 255,255,255;
  --color-error-shade: #e04d4d;
  --color-error-tint: #ff6868;
  /* FONT */
  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Open Sans', sans-serif;
  /* LINE HEIGHT */
  --line-height-body: 1.4;
  --line-height-headings: 1.6;
  /* FONT SIZE */
  --font-size-xs: 0.714em;
  --font-size-s: 0.857em;
  --font-size-m: 1em;
  --font-size-l: 1.143em;
  --font-size-xl: 1.286em;
  --font-size-xxl: 1.429em;
  /* FONT WEIGHT */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  /* ICON SIZE */
  --icon-size-xs: 1em;
  --icon-size-s: 1.5em;
  --icon-size-m: 2em;
  --icon-size-l: 2.5em;
  --icon-size-xl: 3em;
  /* Z-INDEX */
  --zi--50: -50;
  --zi-0: 0;
  --zi-50: 50;
  --zi-100: 100;
  --zi-150: 150;
  --zi-200: 200;
  --zi-300: 300;
  /* SPACING */
  --spacing-baseline: 1em;
  --spacing-xs: calc(var(--spacing-baseline) / 4);
  --spacing-s: calc(var(--spacing-baseline) / 2);
  --spacing-sm: calc((var(--spacing-baseline) / 4) * 3 );
  --spacing-m: var(--spacing-baseline);
  --spacing-ml: calc(var(--spacing-baseline) * 1.5);
  --spacing-l: calc(var(--spacing-baseline) * 2);
  --spacing-xl: calc(var(--spacing-baseline) * 4);
  --spacing-xxl: calc(var(--spacing-baseline) * 8);
  --border-radius-s: 2px;
  --box-shadow-m: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  /* RESPONSIVE */
  --screen-xxxs: 370px;
  --screen-xxs: 400px;
  --screen-xs: 450px;
  --screen-sm: 800px;
  --screen-md: 992px;
  --screen-lg: 1420px;
  --screen-xl: 1600px;
  /* ANIMATION */
  --transition-speed-normal: 0.2s;
  --transition-speed-faster: 0.6s; }
  @media only screen and (min-width: 400px) {
    :root {
      --spacing-baseline: 1.214em; } }

.separator .flexer {
  display: flex; }

.icons .icon {
  margin: 20px;
  font-size: var(--icon-size-m); }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /**************/
  /* Montserrat */
  /**************/
  /************/
  /* OpenSans */
  /************/ }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-Medium.eot");
    src: local("Montserrat Medium"), local("Montserrat-Medium"), url("/assets/fonts//Montserrat/subset-Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-Medium.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-Medium.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-Medium.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-Medium.svg#Montserrat-Medium") format("svg");
    font-weight: 500;
    font-style: normal; } }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-BoldItalic.eot");
    src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("/assets/fonts//Montserrat/subset-Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-BoldItalic.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-BoldItalic.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-BoldItalic.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-BoldItalic.svg#Montserrat-BoldItalic") format("svg");
    font-weight: 700;
    font-style: italic; } }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-Light.eot");
    src: local("Montserrat Light"), local("Montserrat-Light"), url("/assets/fonts//Montserrat/subset-Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-Light.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-Light.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-Light.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-Light.svg#Montserrat-Light") format("svg");
    font-weight: 300;
    font-style: normal; } }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-MediumItalic.eot");
    src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("/assets/fonts//Montserrat/subset-Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-MediumItalic.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-MediumItalic.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-MediumItalic.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-MediumItalic.svg#Montserrat-MediumItalic") format("svg");
    font-weight: 500;
    font-style: italic; } }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-LightItalic.eot");
    src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("/assets/fonts//Montserrat/subset-Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-LightItalic.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-LightItalic.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-LightItalic.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-LightItalic.svg#Montserrat-LightItalic") format("svg");
    font-weight: 300;
    font-style: italic; } }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-Italic.eot");
    src: local("Montserrat Italic"), local("Montserrat-Italic"), url("/assets/fonts//Montserrat/subset-Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-Italic.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-Italic.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-Italic.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-Italic.svg#Montserrat-Italic") format("svg");
    font-weight: 400;
    font-style: italic; } }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-Regular.eot");
    src: local("Montserrat Regular"), local("Montserrat-Regular"), url("/assets/fonts//Montserrat/subset-Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-Regular.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-Regular.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-Regular.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-Regular.svg#Montserrat-Regular") format("svg");
    font-weight: 400;
    font-style: normal; } }

@font-face {
  body {
    font-family: 'Montserrat';
    src: url("/assets/fonts//Montserrat/subset-Montserrat-Bold.eot");
    src: local("Montserrat Bold"), local("Montserrat-Bold"), url("/assets/fonts//Montserrat/subset-Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//Montserrat/subset-Montserrat-Bold.woff2") format("woff2"), url("/assets/fonts//Montserrat/subset-Montserrat-Bold.woff") format("woff"), url("/assets/fonts//Montserrat/subset-Montserrat-Bold.ttf") format("truetype"), url("/assets/fonts//Montserrat/subset-Montserrat-Bold.svg#Montserrat-Bold") format("svg");
    font-weight: 700;
    font-style: normal; } }

@font-face {
  body {
    font-family: 'Open Sans';
    src: url("/assets/fonts//OpenSans/subset-OpenSans-BoldItalic.eot");
    src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("/assets/fonts//OpenSans/subset-OpenSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//OpenSans/subset-OpenSans-BoldItalic.woff2") format("woff2"), url("/assets/fonts//OpenSans/subset-OpenSans-BoldItalic.woff") format("woff"), url("/assets/fonts//OpenSans/subset-OpenSans-BoldItalic.ttf") format("truetype"), url("/assets/fonts//OpenSans/subset-OpenSans-BoldItalic.svg#OpenSans-BoldItalic") format("svg");
    font-weight: 700;
    font-style: italic; } }

@font-face {
  body {
    font-family: 'Open Sans';
    src: url("/assets/fonts//OpenSans/subset-OpenSans-Bold.eot");
    src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/assets/fonts//OpenSans/subset-OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//OpenSans/subset-OpenSans-Bold.woff2") format("woff2"), url("/assets/fonts//OpenSans/subset-OpenSans-Bold.woff") format("woff"), url("/assets/fonts//OpenSans/subset-OpenSans-Bold.ttf") format("truetype"), url("/assets/fonts//OpenSans/subset-OpenSans-Bold.svg#OpenSans-Bold") format("svg");
    font-weight: 700;
    font-style: normal; } }

@font-face {
  body {
    font-family: 'Open Sans';
    src: url("/assets/fonts//OpenSans/subset-OpenSans-Light.eot");
    src: local("Open Sans Light"), local("OpenSans-Light"), url("/assets/fonts//OpenSans/subset-OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//OpenSans/subset-OpenSans-Light.woff2") format("woff2"), url("/assets/fonts//OpenSans/subset-OpenSans-Light.woff") format("woff"), url("/assets/fonts//OpenSans/subset-OpenSans-Light.ttf") format("truetype"), url("/assets/fonts//OpenSans/subset-OpenSans-Light.svg#OpenSans-Light") format("svg");
    font-weight: 300;
    font-style: normal; } }

@font-face {
  body {
    font-family: 'Open Sans';
    src: url("/assets/fonts//OpenSans/subset-OpenSans-LightItalic.eot");
    src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"), url("/assets/fonts//OpenSans/subset-OpenSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//OpenSans/subset-OpenSans-LightItalic.woff2") format("woff2"), url("/assets/fonts//OpenSans/subset-OpenSans-LightItalic.woff") format("woff"), url("/assets/fonts//OpenSans/subset-OpenSans-LightItalic.ttf") format("truetype"), url("/assets/fonts//OpenSans/subset-OpenSans-LightItalic.svg#OpenSans-LightItalic") format("svg");
    font-weight: 300;
    font-style: italic; } }

@font-face {
  body {
    font-family: 'Open Sans';
    src: url("/assets/fonts//OpenSans/subset-OpenSans-Italic.eot");
    src: local("Open Sans Italic"), local("OpenSans-Italic"), url("/assets/fonts//OpenSans/subset-OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//OpenSans/subset-OpenSans-Italic.woff2") format("woff2"), url("/assets/fonts//OpenSans/subset-OpenSans-Italic.woff") format("woff"), url("/assets/fonts//OpenSans/subset-OpenSans-Italic.ttf") format("truetype"), url("/assets/fonts//OpenSans/subset-OpenSans-Italic.svg#OpenSans-Italic") format("svg");
    font-weight: normal;
    font-style: italic; } }

@font-face {
  body {
    font-family: 'Open Sans';
    src: url("/assets/fonts//OpenSans/subset-OpenSans-Regular.eot");
    src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/assets/fonts//OpenSans/subset-OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts//OpenSans/subset-OpenSans-Regular.woff2") format("woff2"), url("/assets/fonts//OpenSans/subset-OpenSans-Regular.woff") format("woff"), url("/assets/fonts//OpenSans/subset-OpenSans-Regular.ttf") format("truetype"), url("/assets/fonts//OpenSans/subset-OpenSans-Regular.svg#OpenSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal; } }
  body {
    font-family: var(--font-primary);
    font-size: var(--font-size-s); }
  body h1,
  body h2,
  body h3 {
    color: inherit;
    margin: 0;
    line-height: var(--line-height-headings);
    font-family: var(--font-primary);
    font-weight: var(--font-weight-bold); }
  body h1, body .h1 {
    font-size: var(--font-size-xxl);
    margin-bottom: var(--spacing-l); }
  body h2, body .h2 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-m); }
  body h3, body .h3 {
    font-size: var(--font-size-l);
    margin-bottom: var(--spacing-s); }
  body p {
    font-size: var(--font-size-m);
    font-family: var(--font-secondary); }
  body .primaryFont {
    font-family: var(--font-primary); }
  body .secondaryFont {
    font-family: var(--font-secondary); }
  body .primaryLink,
  body .secondaryLink {
    font-size: var(--font-size-m);
    text-decoration: underline;
    cursor: pointer;
    font-family: var(--font-secondary); }
  body .primaryLink {
    color: var(--color-primary); }
  body .secondaryLink {
    color: var(--color-gray-dark); }
  body .legal {
    font-size: var(--font-size-s);
    color: var(--color-gray); }
  body .font-large {
    font-size: var(--font-size-l); }
  body .small {
    font-size: var(--font-size-m); }
  body .mini {
    font-size: var(--font-size-xs); }
  body {
    box-sizing: border-box; }
  body *, body *:before, body *:after {
    box-sizing: inherit; }
  body hr {
    border: 0;
    border-top: 1px solid #DFF2F2;
    margin: var(--spacing-l) 0; }
  body .no-margin {
    margin: 0; }
  body .margin-bottom {
    margin-bottom: var(--spacing-m); }
  body .margin-top {
    margin-top: var(--spacing-m); }
  body .margin-start {
    margin-left: var(--spacing-m); }
  body .margin-end {
    margin-right: var(--spacing-m); }
  body .padding-bottom {
    margin-bottom: var(--spacing-m); }
  body .padding-top {
    margin-top: var(--spacing-m); }
  body .padding-start {
    margin-left: var(--spacing-m); }
  body .padding-end {
    margin-right: var(--spacing-m); }

label {
  display: block; }

.pop
.input-group {
  margin-bottom: 8px; }

.props {
  margin-bottom: 32px; }

/*FORM - formikForm.jsx*/
.form {
  margin: 0 auto; }
  .form .container {
    border-bottom: 2px solid #DFF2F2;
    max-width: 450px;
    margin: 10px;
    padding-bottom: 20px; }
  .form .flex {
    display: flex;
    justify-content: space-between; }
  .form .center {
    text-align: center; }

.wrapper {
  padding: 10px;
  display: flex;
  width: 15rem; }

/*POPUP - popup.jsx*/
.popup {
  text-align: center;
  text-decoration: none; }

.popup.afterClick {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #46ACA6;
  color: white; }

.popup.p {
  width: 80%;
  padding-left: 2rem;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px; }

.closeButton {
  position: absolute;
  top: 20px;
  right: 10px;
  color: white; }

.spacingBaseline {
  padding-bottom: var(--spacing-baseline); }
