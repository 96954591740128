$font-path: '/assets/fonts/' !default;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

/**************/
/* Montserrat */
/**************/

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Medium.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Medium.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Medium.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: $font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-BoldItalic.eot');
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
  url('#{$font-path}/Montserrat/subset-Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-BoldItalic.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-BoldItalic.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-BoldItalic.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
  font-weight: $font-weight-bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Light.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Light.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Light.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-MediumItalic.eot');
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
  url('#{$font-path}/Montserrat/subset-Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-MediumItalic.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-MediumItalic.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-MediumItalic.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
  font-weight: $font-weight-medium;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-LightItalic.eot');
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
  url('#{$font-path}/Montserrat/subset-Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-LightItalic.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-LightItalic.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-LightItalic.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
  font-weight: $font-weight-light;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-Italic.eot');
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Italic.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Italic.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Italic.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Italic.svg#Montserrat-Italic') format('svg');
  font-weight: $font-weight-normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Regular.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Regular.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Regular.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: $font-weight-normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/subset-Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Bold.woff2') format('woff2'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Bold.woff') format('woff'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Bold.ttf') format('truetype'),
  url('#{$font-path}/Montserrat/subset-Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: $font-weight-bold;
  font-style: normal;
}


/************/
/* OpenSans */
/************/

@font-face {
  font-family: 'Open Sans';
  src: url('#{$font-path}/OpenSans/subset-OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('#{$font-path}/OpenSans/subset-OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-BoldItalic.woff2') format('woff2'),
  url('#{$font-path}/OpenSans/subset-OpenSans-BoldItalic.woff') format('woff'),
  url('#{$font-path}/OpenSans/subset-OpenSans-BoldItalic.ttf') format('truetype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: $font-weight-bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$font-path}/OpenSans/subset-OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Bold.woff2') format('woff2'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Bold.woff') format('woff'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Bold.ttf') format('truetype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$font-path}/OpenSans/subset-OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Light.woff2') format('woff2'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Light.woff') format('woff'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Light.ttf') format('truetype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$font-path}/OpenSans/subset-OpenSans-LightItalic.eot');
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('#{$font-path}/OpenSans/subset-OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-LightItalic.woff2') format('woff2'),
  url('#{$font-path}/OpenSans/subset-OpenSans-LightItalic.woff') format('woff'),
  url('#{$font-path}/OpenSans/subset-OpenSans-LightItalic.ttf') format('truetype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
  font-weight: $font-weight-light;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$font-path}/OpenSans/subset-OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Italic.woff2') format('woff2'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Italic.woff') format('woff'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Italic.ttf') format('truetype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$font-path}/OpenSans/subset-OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Regular.woff2') format('woff2'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Regular.woff') format('woff'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Regular.ttf') format('truetype'),
  url('#{$font-path}/OpenSans/subset-OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

& {
  font-family: var(--font-primary);
  font-size: var(--font-size-s)
}

h1,
h2,
h3 {
  color: inherit;
  margin: 0;
  line-height: var(--line-height-headings);
  font-family: var(--font-primary);
  font-weight: var(--font-weight-bold);
}

h1, .h1 {
  font-size: var(--font-size-xxl);
  margin-bottom: var(--spacing-l);
}

h2, .h2 {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-m);
}

h3, .h3 {
  font-size: var(--font-size-l);
  margin-bottom: var(--spacing-s);
}

p {
  font-size: var(--font-size-m);
  font-family: var(--font-secondary);
}

.primaryFont {
  font-family: var(--font-primary);
}

.secondaryFont {
  font-family: var(--font-secondary);
}

.primaryLink,
.secondaryLink {
  font-size: var(--font-size-m);
  text-decoration: underline;
  cursor: pointer;
  font-family: var(--font-secondary);
}

.primaryLink {
  color: var(--color-primary);
}

.secondaryLink {
  color: var(--color-gray-dark);
}

.legal {
  font-size: var(--font-size-s);
  color: var(--color-gray);
}

.font-large {
  font-size: var(--font-size-l);
}

.small {
  font-size: var(--font-size-m);
}

.mini {
  font-size: var(--font-size-xs);
}

