& {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

hr {
  border: 0;
  border-top: 1px solid #DFF2F2;
  margin: var(--spacing-l) 0;
}
