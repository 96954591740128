// Margins

.no-margin {
  margin: 0;
}

.margin-bottom {
  margin-bottom: var(--spacing-m);
}

.margin-top {
  margin-top: var(--spacing-m);
}

.margin-start {
  margin-left: var(--spacing-m);
}

.margin-end {
  margin-right: var(--spacing-m);
}

// Paddings

.padding-bottom {
  margin-bottom: var(--spacing-m);
}

.padding-top {
  margin-top: var(--spacing-m);
}

.padding-start {
  margin-left: var(--spacing-m);
}

.padding-end {
  margin-right: var(--spacing-m);
}
